/*!
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

*,:after,:before{
	box-sizing:border-box;
}

html{
	font-family:system-ui,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
	line-height:1.15;
	-webkit-text-size-adjust:100%;
	-moz-tab-size:4;
	-o-tab-size:4;
	   tab-size:4;
}

body{
	margin:0;
}

hr{
	height:0;
	color:inherit;
}

abbr[title]{
	text-decoration:underline;
	-webkit-text-decoration:underline dotted;
	        text-decoration:underline dotted;
}

b,strong{
	font-weight:bolder;
}

code,kbd,pre,samp{
	font-family:ui-monospace,SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
	font-size:1em;
}

small{
	font-size:80%;
}

sub,sup{
	font-size:75%;
	line-height:0;
	position:relative;
	vertical-align:baseline;
}

sub{
	bottom:-.25em;
}

sup{
	top:-.5em;
}

table{
	text-indent:0;
	border-color:inherit;
}

button,input,optgroup,select,textarea{
	font-size:100%;
	line-height:1.15;
	margin:0;
}

button,select{
	text-transform:none;
}

[type=button],[type=reset],[type=submit],button{
	-webkit-appearance:button;
}

::-moz-focus-inner{
	border-style:none;
	padding:0;
}

:-moz-focusring{
	outline:1px dotted ButtonText;
}

:-moz-ui-invalid{
	box-shadow:none;
}

legend{
	padding:0;
}

progress{
	vertical-align:baseline;
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button{
	height:auto;
}

[type=search]{
	-webkit-appearance:textfield;
	outline-offset:-2px;
}

::-webkit-search-decoration{
	-webkit-appearance:none;
}

::-webkit-file-upload-button{
	-webkit-appearance:button;
	font:inherit;
}

summary{
	display:list-item;
}

:root{
  --zd-color-black:#000;
  --zd-color-green-100:#edf8f4;
  --zd-color-green-200:#d1e8df;
  --zd-color-green-300:#aecfc2;
  --zd-color-green-400:#5eae91;
  --zd-color-green-500:#228f67;
  --zd-color-green-600:#038153;
  --zd-color-green-700:#186146;
  --zd-color-green-800:#0b3b29;
  --zd-color-grey-100:#f8f9f9;
  --zd-color-grey-200:#e9ebed;
  --zd-color-grey-300:#d8dcde;
  --zd-color-grey-400:#c2c8cc;
  --zd-color-grey-500:#87929d;
  --zd-color-grey-600:#68737d;
  --zd-color-grey-700:#49545c;
  --zd-color-grey-800:#2f3941;
  --zd-color-blue-100:#edf7ff;
  --zd-color-blue-200:#cee2f2;
  --zd-color-blue-300:#adcce4;
  --zd-color-blue-400:#5293c7;
  --zd-color-blue-500:#337fbd;
  --zd-color-blue-600:#1f73b7;
  --zd-color-blue-700:#144a75;
  --zd-color-blue-800:#0f3554;
  --zd-color-kale-100:#f5fcfc;
  --zd-color-kale-200:#daeded;
  --zd-color-kale-300:#bdd9d7;
  --zd-color-kale-400:#90bbbb;
  --zd-color-kale-500:#467b7c;
  --zd-color-kale-600:#17494d;
  --zd-color-kale-700:#03363d;
  --zd-color-kale-800:#012b30;
  --zd-color-red-100:#fff0f1;
  --zd-color-red-200:#f5d5d8;
  --zd-color-red-300:#f5b5ba;
  --zd-color-red-400:#e35b66;
  --zd-color-red-500:#d93f4c;
  --zd-color-red-600:#cc3340;
  --zd-color-red-700:#8c232c;
  --zd-color-red-800:#681219;
  --zd-color-yellow-100:#fff7ed;
  --zd-color-yellow-200:#ffeedb;
  --zd-color-yellow-300:#fed6a8;
  --zd-color-yellow-400:#ffb057;
  --zd-color-yellow-500:#f79a3e;
  --zd-color-yellow-600:#ed8f1c;
  --zd-color-yellow-700:#ad5918;
  --zd-color-yellow-800:#703815;
  --zd-color-white:#fff;
  --zd-color-secondary-azure-400:#3091ec;
  --zd-color-secondary-azure-600:#1371d6;
  --zd-color-secondary-crimson-400:#e34f32;
  --zd-color-secondary-crimson-600:#c72a1c;
  --zd-color-secondary-fuschia-400:#d653c2;
  --zd-color-secondary-fuschia-600:#a81897;
  --zd-color-secondary-lemon-400:#ffd424;
  --zd-color-secondary-lemon-600:#ffbb10;
  --zd-color-secondary-lime-400:#43b324;
  --zd-color-secondary-lime-600:#2e8200;
  --zd-color-secondary-mint-400:#00a656;
  --zd-color-secondary-mint-600:#058541;
  --zd-color-secondary-orange-400:#de701d;
  --zd-color-secondary-orange-600:#bf5000;
  --zd-color-secondary-pink-400:#ec4d63;
  --zd-color-secondary-pink-600:#d42054;
  --zd-color-secondary-purple-400:#b552e2;
  --zd-color-secondary-purple-600:#6a27b8;
  --zd-color-secondary-royal-400:#5d7df5;
  --zd-color-secondary-royal-600:#3353e2;
  --zd-color-secondary-teal-400:#02a191;
  --zd-color-secondary-teal-600:#028079;
  --zd-color-secondary-azure-M400:#5f8dcf;
  --zd-color-secondary-azure-M600:#3a70b2;
  --zd-color-secondary-crimson-M400:#cc6c5b;
  --zd-color-secondary-crimson-M600:#b24a3c;
  --zd-color-secondary-fuschia-M400:#cf62a8;
  --zd-color-secondary-fuschia-M600:#a8458c;
  --zd-color-secondary-lemon-M400:#e7a500;
  --zd-color-secondary-lemon-M600:#c38f00;
  --zd-color-secondary-lime-M400:#519e2d;
  --zd-color-secondary-lime-M600:#47782c;
  --zd-color-secondary-mint-M400:#299c66;
  --zd-color-secondary-mint-M600:#2e8057;
  --zd-color-secondary-orange-M400:#d4772c;
  --zd-color-secondary-orange-M600:#b35827;
  --zd-color-secondary-pink-M400:#d57287;
  --zd-color-secondary-pink-M600:#b23a5d;
  --zd-color-secondary-purple-M400:#b072cc;
  --zd-color-secondary-purple-M600:#9358b0;
  --zd-color-secondary-royal-M400:#7986d8;
  --zd-color-secondary-royal-M600:#4b61c3;
  --zd-color-secondary-teal-M400:#2d9e8f;
  --zd-color-secondary-teal-M600:#3c7873;
  --zd-color-chat-orange:#f79a3e;
  --zd-color-connect-red:#ff6224;
  --zd-color-explore-blue:#30aabc;
  --zd-color-gather-pink:#f6c8be;
  --zd-color-guide-pink:#ff6224;
  --zd-color-message-green:#37b8af;
  --zd-color-sell-gold:#c38f00;
  --zd-color-support-green:#00a656;
  --zd-color-talk-yellow:#efc93d;
  --zd-font-family-monospace:SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;
  --zd-font-family-system:system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
  --zd-font-size-xs:10px;
  --zd-font-size-sm:12px;
  --zd-font-size-md:14px;
  --zd-font-size-lg:18px;
  --zd-font-size-xl:22px;
  --zd-font-size-xxl:26px;
  --zd-font-size-xxxl:36px;
  --zd-font-size-sm-monospace:11px;
  --zd-font-size-md-monospace:13px;
  --zd-font-size-lg-monospace:17px;
  --zd-font-weight-thin:100;
  --zd-font-weight-extralight:200;
  --zd-font-weight-light:300;
  --zd-font-weight-regular:400;
  --zd-font-weight-medium:500;
  --zd-font-weight-semibold:600;
  --zd-font-weight-bold:700;
  --zd-font-weight-extrabold:800;
  --zd-font-weight-black:900;
  --zd-font-weight-ultralight:200;
  --zd-font-weight-ultrabold:800;
  --zd-font-weight-heavy:900;
  --zd-line-height-sm:16px;
  --zd-line-height-md:20px;
  --zd-line-height-lg:24px;
  --zd-line-height-xl:28px;
  --zd-line-height-xxl:32px;
  --zd-line-height-xxxl:44px;
  --zd-spacing-xxs:4px;
  --zd-spacing-xs:8px;
  --zd-spacing-sm:12px;
  --zd-spacing:20px;
  --zd-spacing-lg:32px;
  --zd-spacing-xl:40px;
  --zd-spacing-xxl:48px;
  --zd-anchor-color:var(--zd-color-blue-600);
  --zd-html-background-color:var(--zd-color-white);
  --zd-html-box-sizing:border-box;
  --zd-html-font-family:var(--zd-font-family-system);
  --zd-html-font-feature-settings:"kern";
  --zd-html-font-kerning:normal;
  --zd-html-font-size:var(--zd-font-size-md);
  --zd-html-line-height:var(--zd-spacing);
  --zd-html-text-color:var(--zd-color-grey-800);
}

html{
  background-color:#fff;
  min-height:100%;
  box-sizing:border-box;
  overflow-y:scroll;
  line-height:20px;
  color:#2f3941;
  font-feature-settings:"kern", normal;
  font-kerning:normal;
  font-family:system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Arial,sans-serif;
  font-size:14px;
}

*{ font-weight:inherit; }

*,:after,:before{ box-sizing:inherit; }

a{
  transition:outline-color .1s ease-in-out,color .25s ease-in-out;
  outline:2px solid transparent;
  outline-offset:1px;
  border-radius:4px;
  color:#1f73b7;
}

a,ins,u{ -webkit-text-decoration:none; text-decoration:none; }

a:hover{
  -webkit-text-decoration:underline;
  text-decoration:underline;
  color:#144a75;
}

a:active{
  color:#0f3554;
}

a:focus-visible{
  outline-color:#1f73b7;
  -webkit-text-decoration:none;
  text-decoration:none;
}

a:focus-visible:hover{
  -webkit-text-decoration:underline;
  text-decoration:underline;
}

b{ font-weight:600; }

button{
  cursor:pointer;
  padding:0;
}

button,input,optgroup,select,textarea{
  line-height:inherit;
  font-family:inherit;
}

code{ font-size:.95em; }

code,kbd,pre,samp{ font-family:SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace; }

em{ font-style:inherit; }

fieldset,iframe{ border:0; }

h1,h2,h3,h4,h5,h6{ font-size:inherit; }

blockquote,dd,dl,fieldset,figure,h1,h2,h3,h4,h5,h6,hr,ol,p,pre,ul{
  margin:0;
  padding:0;
}

hr{
  border:none;
  border-top:1px solid;
}

ol,ul{ list-style:none; }

img{ max-width:100%; }

strong{ font-weight:inherit; }

svg{ max-height:100%; }

[tabindex="-1"]:focus{ outline:none !important; }
